import { useState } from "react";
import { getClasses } from "../constants/theme";

export default function RadioTabs(props) {
  const [pressed, setPressed] = useState(props.default);
  const classes = getClasses(dynamicStyle);

  const select = (value) => {
    setPressed(value);
    props.onChanged && props.onChanged(value);
  };

  return (
    <div className={classes("wrapper")}>
      {props.items.map((item) => {
        return (
          <div
            className={classes(
              item.value === pressed ? "selected" : void 0,
              "item"
            )}
            onClick={() => select(item.value)}
          >
            {item.name}
          </div>
        );
      })}
    </div>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    wrapper: {
      display: "flex",
      flexDirection: "row",
    },
    item: {
      ...Fonts.subhead2,
      color: "#0560FD",
      padding: "0px 24px",
      borderRadius: 8,
      cursor: "pointer",
      height: 42,
      display: "flex",
      alignItems: "center",
      backgroundColor: "#E9F5FF",
      marginRight:8
    },
    selected: {
      color: "#FFFFFF",
      backgroundColor: "#0560FD",
    },
  };
};

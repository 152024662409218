import "./App.css";
import Dialog from "./components/Dialog";
import Notifications from "./components/Notifications";
import useStore from "./hooks/useStore";
import Navigation from "./navigation";
import { Settings } from "./stores/Settings";

function App() {
  const ready = useStore();

  if (!ready) return null;

  return (
    <>
      <Navigation />
      <Dialog />
      <Notifications />
    </>
  );
}

export default App;

(function () {
  var throttle = function (type, name, obj) {
    obj = obj || window;
    var running = false;
    var func = function () {
      if (running) {
        return;
      }
      running = true;
      requestAnimationFrame(function () {
        obj.dispatchEvent(new CustomEvent(name));
        running = false;
      });
    };
    obj.addEventListener(type, func);
  };

  /* init - you can init any event */
  throttle("resize", "optimizedResize");
})();

// handle event
window.addEventListener("optimizedResize", function () {
  Settings.memorize("windowsize", {
    width: window.innerWidth,
    height: window.innerHeight,
  });
});


console.error = () => {}
console.warn = () => {}
console.log = () => {}
import { useParams } from "react-router-dom";
import Button from "../../../components/Button";
import Title2 from "../../../components/Layout/Title2";
import { pureGet } from "../../../utils";
import LessaonItem from "../../CreateNewCourse/components/LessonItem";

export default function CourseLessons(props) {
  const course = props?.course ?? {};
  const { id, type, index, hindex } = useParams();
  const lesson = pureGet(course, [type, index]);

  return (
    <>
      <Title2>Уроки</Title2>
      <LessaonItem
        to={`/courses/${id}/start`}
        selected={type === undefined}
        title={"Страница курса"}
      />
      <div style={{ marginTop: 16, display: "flex", flexDirection: "column" }}>
        <LessaonItem
          selected={0 == index && type === "introductory_lesson"}
          to={`/courses/${id}/lessons/introductory_lesson/0`}
          title={pureGet(course, "introductory_lesson.0.title")}
          subtitle={`Вступительный урок`}
        />
        {pureGet(course, "lessons", []).map((item, i) => {
          return (
            <LessaonItem
              selected={i == index && type === "lessons" && hindex == undefined}
              title={item.title || "Нет названия"}
              subtitle={`Урок ${i + 1}`}
              item={item}
              key={item.id}
              to={`/courses/${id}/lessons/lessons/${i}`}
            />
          );
        })}
        <LessaonItem
          selected={0 == index && type === "final_lesson"}
          to={`/courses/${id}/lessons/final_lesson/0`}
          title={pureGet(course, "final_lesson.0.title")}
          subtitle={`Завершающий урок`}
        />
      </div>
      <div style={{ marginTop: 16, display: "flex", flexDirection: "column" }}>
        <Title2>Задания</Title2>
        {pureGet(course, "lessons", []).map((item, i) => {
          if (item.hometasks.length === 0) return null;
          return (
            <LessaonItem
              selected={i == index && type === "lessons" && hindex == 0}
              title={item.title || "Нет названия"}
              subtitle={`Урок ${i + 1}`}
              item={item}
              key={item.id}
              to={`/courses/${id}/lessons/lessons/${i}/hometask/0`}
            />
          );
        })}
      </div>
    </>
  );
}

import moment from "moment";
import React, { useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { getStudyGroups } from "../../api";
import Link from "../../components/Link";
import InnerHeader from "../../components/Page/InnerHeader";
import Table from "../../components/Table";
import TableEnd from "../../components/TableEnd";
import { getClasses } from "../../constants/theme";
import { omitUndefined } from "../../utils";
import { beautifyAmount } from "../../utils/currency";
import RadioTabs from "./../../components/RadioTabs";

// '_id',
// 'created_at',
// 'status',
// 'title',
// 'counter',
// 'member_count',
// 'created_by._id',
// 'created_by.first_name',
// 'created_by.last_name',
// 'started_at',
// 'course_type',
// 'course_price.amount',
// 'course_price.currency',

const getColumns = (classes) => ({
  _id: {
    f: 1,
    title: "ID",
    converter: (_id) => {
      return <Link to={`/transactions/${_id}`}>{_id}</Link>;
    },
  },
  counter: {
    f: 0.5,
    title: "#",
  },
  title: {
    f: 2,
    title: "Название",
  },

  member_count: {
    f: 0.5,
    title: "Участников",
  },
  course_price: {
    f: 1,
    title: "Стоимость",
    converter: (course_price) => {
      return course_price ? beautifyAmount(course_price) : 'Бесплатно';
    },
  },
  created_by: {
    title: "Пользователь",
    converter: (cl) => {
      return (
        <Link to={`/clients/${cl._id}`}>
          {cl.nickname ?? `${cl.last_name} ${cl.first_name}`}
        </Link>
      );
    },
  },

  created_at: {
    title: "Создана",
    converter: (created_at) => {
      return moment(created_at).format("DD.MM.YY HH:mm");
    },
  },
});

export default function StudyGroupsList() {
  const classes = getClasses(dynamicStyle);
  const table = useRef();
  let [searchParams, setSearchParams] = useSearchParams();
  const columns = useMemo(() => getColumns(classes), []);
  const serachOptions = useRef({
    query: searchParams.get("query") ?? void 0,
    mode: searchParams.get("action") ?? "all",
  });

  return (
    <div className={classes("wrapper")}>
      <InnerHeader
        defaultQuery={serachOptions.current.query}
        onQuerySubmit={(query) => {
          if (query) {
            serachOptions.current.query = query;
          } else {
            delete serachOptions.current.query;
          }

          setSearchParams(omitUndefined(serachOptions.current));

          table.current.reload();
        }}
        title={"Курсы"}
      />
      <div className={classes("content")}>
        <div className={classes("filtersSection")}>
          <RadioTabs
            onChanged={(v) => {
              serachOptions.current.mode = v;
              setSearchParams(omitUndefined(serachOptions.current));

              table.current.reload();
            }}
            default={serachOptions.current.mode}
            items={[
              {
                value: "all",
                name: "Все",
              },
              {
                value: "started",
                name: "Актуальные",
              },
              {
                value: "finished",
                name: "Завершенные",
              },
            ]}
          />
        </div>
        <Table
          offset={205}
          ref={table}
          endComponent={<TableEnd />}
          size={20}
          columns={columns}
          fetchData={(param) =>
            getStudyGroups({ ...param, ...serachOptions.current })
          }
        />
      </div>
    </div>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    filtersSection: {
      marginBottom: 32,
    },
    content: {
      paddingLeft: 40,
      paddingTop: 30,
    },
    searchItem: {
      marginBottom: 4,
    },
    searchLabel: {
      ...Fonts.subhead2,
      color: Colors.neutral1,
      marginBottom: 2,
    },
    arraowDown: {
      color: Colors.accent2,
    },
    arraowUp: {
      color: Colors.color1,
    },
    wrapper: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
  };
};
